import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import {DataResponse} from "@shared/models/response.model";
import {Location} from "@shared/models/area/location.model";
import {Address} from "@shared/models/address.model";
import {LocationService} from "@shared/services/area/location.service";

interface LocationAddConfig {
  companyId: number;
}

@Component({
  selector: 'saf-area-location-select-add',
  templateUrl: './area-location-select-add.component.html',
  styleUrls: ['./area-location-select-add.component.scss']
})
export class AreaLocationSelectAddComponent implements OnInit {
  public loading: boolean = false;
  public formGroup: UntypedFormGroup;
  public location: Location;
  public address: Address;
  public companyId: number = null;

  constructor(
    private dialogRef: MatDialogRef<AreaLocationSelectAddComponent>,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: LocationAddConfig
  ) { }

  ngOnInit(): void {
    this.companyId = this.data.companyId;
    this.location = new Location();
    this.address = new Address();
    this.buildForm(this.location, this.address);
  }

  buildForm(location: Location, address: Address) {
    this.formGroup = new UntypedFormGroup({
      companyId: new UntypedFormControl(location.companyId ? location.companyId : this.companyId, Validators.required),
      name: new UntypedFormControl(location.name, Validators.required),
      parentLocationId: new UntypedFormControl(location.parentLocationId),
      countryId: new UntypedFormControl(address.countryId, Validators.required),
      street: new UntypedFormControl(address.street, Validators.required),
      zipCode: new UntypedFormControl(address.zipCode, Validators.required),
      city: new UntypedFormControl(address.city, Validators.required),
    });

    this.location = location;
    this.address = address;
  }

  submitForm() {
    if (this.formGroup.valid) {
      const request= this.locationService.create(this.formGroup.value);

      this.loading = true;

      request
        .pipe(tap(() => this.loading = false))
        .subscribe((result) => this.handleResult(result));
    }
  }

  handleResult(result: DataResponse<Location>) {
    if (result.success) {
      this.close(true, result.data);
    }
  }

  close(result: boolean = false, data: Location = null) {
    this.dialogRef.close({result, data});
  }
}
