import {Component, OnInit} from '@angular/core';
import {ModuleName} from '@shared/models/module-name.model';
import {ModuleColor} from '@shared/models/module-color';
import {AuthState} from '@shared/store/auth/auth.state';
import {Select, Store} from '@ngxs/store';
import {PermissionKeys} from '@shared/models/permission.model';


interface NavigationGroup {
  label: string;
  icon: string;
  moduleColor?: ModuleColor;
  module?: ModuleName;
  routes?: string;
  navigation?: string;
  children?: NavigationGroup[];
  permission?: string;
  add?: boolean;
}

@Component({
  selector: 'saf-navigation-with-child',
  templateUrl: './navigation-with-child.component.html',
  styleUrls: ['./navigation-with-child.component.scss']
})


export class NavigationWithChildComponent implements OnInit {
  @Select(AuthState.userDetails) userDetails$;
  public dash: NavigationGroup[] = [
    {
      label: 'dashboard',
      icon: 'dashboard',
      routes: '/dashboard',
    }];
  public categories: NavigationGroup[] = [
    {
      label: 'navigation',
      icon: 'dashboard',
      moduleColor: ModuleColor.NAVIGATION,
      routes: '/dashboard',
    },
    {
      label: 'safety-Ehs',
      icon: 'room_preferences',
      moduleColor: ModuleColor.USER_ADMINISTRATION,
      children: [
        {
          routes: '/department',
          label: 'corporateStructure',
          module: ModuleName.USER_ADMINISTRATION,
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'domain',
          navigation: 'administration'
        }, {
          routes: '/employee',
          label: 'employees',
          module: ModuleName.USER_ADMINISTRATION,
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'group',
          navigation: 'administration'
        },
        {
          routes: '/employee-category',
          label: 'employeeCategories',
          module: ModuleName.USER_ADMINISTRATION,
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'category',
          navigation: 'administration'
        },
        {
          routes: '/permission-profile',
          label: 'permissionProfiles',
          module: ModuleName.USER_ADMINISTRATION,
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'security',
          navigation: 'miscellaneous'
        },
        {
          routes: '/cost-center',
          label: 'costCenters',
          module: ModuleName.USER_ADMINISTRATION,
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'payments',
          navigation: 'administration'
        },
        {
          routes: '/mailingSettings',
          label: 'notificationSystem',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'mail',
          navigation: 'miscellaneous'
        },
        {
          routes: '/checklist',
          label: 'checklists',
          module: ModuleName.USER_ADMINISTRATION,
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'assignment_turned_in',
          navigation: 'miscellaneous'
        },
        {
          routes: '/file-system',
          label: 'fileStorage',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          module: ModuleName.USER_ADMINISTRATION,
          navigation: 'administration',
          icon: 'folder_open'
        },
        {
          routes: '/area-location',
          label: 'locations',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          module: ModuleName.USER_ADMINISTRATION,
          navigation: 'administration',
          icon: 'location_on'
        },
        {
          routes: '/license',
          label: 'licenses',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'receipt_long',
          navigation: 'administration'
        },
        {
          routes: '/analyse',
          label: 'analyses',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'pie_chart',
          navigation: 'administration'
        },
        {
          routes: '/symbol-icon',
          label: 'symbol-icon',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'photo_library',
          navigation: 'administration'
        },
        {
          routes: '/roles',
          label: 'roles',
          moduleColor: ModuleColor.USER_ADMINISTRATION,
          icon: 'manage_accounts',
          navigation: 'administration'
        }
      ]
    },
    {
      label: 'observations',
      icon: 'visibility',
      moduleColor: ModuleColor.OBSERVATION,
      children: [
        // {
        //   routes: '/observation/create',
        //   label: 'addNewObservation',
        //   module: ModuleName.OBSERVATION,
        //   moduleColor: ModuleColor.OBSERVATION,
        //   icon: 'visibility',
        //   navigation: 'observations',
        //   permission: PermissionKeys.OBSERVATION_EDIT,
        //   add: true
        // },
        {
          routes: '/observation',
          label: 'observation-overview',
          module: ModuleName.OBSERVATION,
          moduleColor: ModuleColor.OBSERVATION,
          icon: 'visibility',
          navigation: 'observations'
        },
        {
          routes: '/observation-type',
          label: 'observationTypes',
          module: ModuleName.OBSERVATION,
          moduleColor: ModuleColor.OBSERVATION,
          icon: 'pie_chart',
          navigation: 'observations'
        },
      ]
    },
    {
      label: 'auditsAndInspections',
      icon: 'find_in_page',
      moduleColor: ModuleColor.AUDIT,
      children: [
        // {
        //   routes: '/audit/create',
        //   label: 'newAudit',
        //   module: ModuleName.AUDIT,
        //   moduleColor: ModuleColor.AUDIT,
        //   icon: 'find_in_page',
        //   navigation: 'auditsAndInspections',
        //   permission: PermissionKeys.AUDIT_EDIT,
        //   add: true
        // },
        {
          routes: '/audit',
          label: 'audit-overview',
          module: ModuleName.AUDIT,
          moduleColor: ModuleColor.AUDIT,
          icon: 'find_in_page',
          navigation: 'auditsAndInspections'
        },
        // {
        //   routes: '/inspection/create',
        //   label: 'newInspection',
        //   module: ModuleName.AUDIT,
        //   moduleColor: ModuleColor.AUDIT,
        //   icon: 'search',
        //   navigation: 'auditsAndInspections',
        //   permission: PermissionKeys.AUDIT_INSPECTION_EDIT,
        //   add: true
        // },
        {
          routes: '/inspection',
          label: 'inspection-overview',
          module: ModuleName.AUDIT,
          moduleColor: ModuleColor.AUDIT,
          icon: 'search',
          navigation: 'auditsAndInspections'
        },
        {
          routes: '/inspection-checklist',
          label: 'checklists',
          module: ModuleName.AUDIT,
          moduleColor: ModuleColor.AUDIT,
          icon: 'assignment_turned_in',
          navigation: 'auditsAndInspections'
        },

      ]
    },
    {
      label: 'hazardAssessments',
      icon: 'warning',
      moduleColor: ModuleColor.HAZARD_ASSESSMENT,
      children: [
        // {
        //   routes: '/hazard-assessment/create',
        //   label: 'addNewHazardAssessment',
        //   module: ModuleName.HAZARD_ASSESSMENT,
        //   moduleColor: ModuleColor.HAZARD_ASSESSMENT,
        //   icon: 'warning',
        //   navigation: 'hazardAssessments',
        //   permission: PermissionKeys.HAZARD_ASSESSMENT_ADD,
        //   add: true
        // },
        {
          routes: '/hazard-assessment',
          label: 'hazard-overview',
          module: ModuleName.HAZARD_ASSESSMENT,
          moduleColor: ModuleColor.HAZARD_ASSESSMENT,
          icon: 'warning',
          navigation: 'hazardAssessments'
        },
        {
          routes: '/hazard-assessment-template-category',
          label: 'templateCategories',
          module: ModuleName.HAZARD_ASSESSMENT,
          moduleColor: ModuleColor.HAZARD_ASSESSMENT,
          icon: 'category',
          navigation: 'hazardAssessments'
        },
        {
          routes: '/hazard-assessment-template',
          label: 'templates',
          module: ModuleName.HAZARD_ASSESSMENT,
          moduleColor: ModuleColor.HAZARD_ASSESSMENT,
          icon: 'list',
          navigation: 'hazardAssessments'
        },
        {
          routes: '/hazard-group',
          label: 'groupsAndFactors',
          module: ModuleName.HAZARD_ASSESSMENT,
          moduleColor: ModuleColor.HAZARD_ASSESSMENT,
          icon: 'group_work',
          navigation: 'hazardAssessments'
        },
        // {
        //   routes: '/hazard-checklist',
        //   label: 'checklists',
        //   module: ModuleName.HAZARD_ASSESSMENT,
        //   moduleColor: ModuleColor.HAZARD_ASSESSMENT,
        //   icon: 'assignment_turned_in',
        //   navigation: 'hazardAssessments'
        // },
        {
          routes: '/hazard-law',
          label: 'lexica',
          module: ModuleName.HAZARD_ASSESSMENT,
          moduleColor: ModuleColor.HAZARD_ASSESSMENT,
          icon: 'gavel',
          navigation: 'hazardAssessments'
        },
        {
          routes: '/hazard-rating-type',
          label: 'riskAssessments',
          module: ModuleName.HAZARD_ASSESSMENT,
          moduleColor: ModuleColor.HAZARD_ASSESSMENT,
          icon: 'trending_up',
          navigation: 'hazardAssessments'
        },
      ],
    },
    {
      label: 'instructions',
      icon: 'assignment',
      moduleColor: ModuleColor.INSTRUCTION,
      children: [
        // {
        //   routes: '/instruction/create',
        //   label: 'addNewInstruction',
        //   module: ModuleName.INSTRUCTION,
        //   moduleColor: ModuleColor.INSTRUCTION,
        //   icon: 'assignment',
        //   navigation: 'instructions',
        //   permission: PermissionKeys.INSTRUCTION_ADD,
        //   add: true
        // },
        {
          routes: '/instruction',
          label: 'instruction-overview',
          module: ModuleName.INSTRUCTION,
          moduleColor: ModuleColor.INSTRUCTION,
          icon: 'assignment',
          navigation: 'instructions'
        },
        {
          routes: '/instruction-template',
          label: 'textblocks',
          module: ModuleName.INSTRUCTION,
          moduleColor: ModuleColor.INSTRUCTION,
          icon: 'extension',
          navigation: 'instructions'
        },
        {
          routes: '/instruction-pattern',
          label: 'templates',
          module: ModuleName.INSTRUCTION,
          moduleColor: ModuleColor.INSTRUCTION,
          icon: 'list',
          navigation: 'instructions'
        },
        {
          routes: '/instruction-type',
          label: 'instructionTypes',
          module: ModuleName.INSTRUCTION,
          moduleColor: ModuleColor.INSTRUCTION,
          icon: 'category',
          navigation: 'instructions'
        }
      ]
    },
    {
      label: 'schoolings',
      icon: 'school',
      moduleColor: ModuleColor.SCHOOLING,
      children: [
        // {
        //   routes: '/schooling-plan/multiple',
        //   label: 'schoolingPlanAdd',
        //   moduleColor: ModuleColor.SCHOOLING,
        //   module: ModuleName.SCHOOLING,
        //   navigation: 'schoolings',
        //   icon: 'event',
        //   permission: PermissionKeys.SCHOOLING_PLAN_EDIT,
        //   add: true
        // },
        {
          routes: '/schooling-plan',
          label: 'schoolingPlanOverview',
          moduleColor: ModuleColor.SCHOOLING,
          module: ModuleName.SCHOOLING,
          navigation: 'schoolings',
          icon: 'calendar_month'
        },
        // {
        //   routes: '/schooling/create',
        //   label: 'personalSchoolingAdd',
        //   moduleColor: ModuleColor.SCHOOLING,
        //   module: ModuleName.SCHOOLING,
        //   navigation: 'schoolings',
        //   icon: 'school',
        //   permission: PermissionKeys.SCHOOLING_TUITION_EDIT,
        //   add: true
        // },
        // {
        //   routes: '/schooling-plan-topic',
        //   label: 'schoolingPlanTopicOverview',
        //   moduleColor: ModuleColor.SCHOOLING,
        //   module: ModuleName.SCHOOLING,
        //   navigation: 'schoolings',
        //   icon: 'calendar_month'
        // },
        {
          routes: '/schooling',
          label: 'personalSchoolings',
          moduleColor: ModuleColor.SCHOOLING,
          module: ModuleName.SCHOOLING,
          navigation: 'schoolings',
          icon: 'school'
        },
        {
          routes: '/schooling-topic',
          label: 'schoolingTopicOverview',
          moduleColor: ModuleColor.SCHOOLING,
          module: ModuleName.SCHOOLING,
          navigation: 'schoolings',
          icon: 'list_alt'
        },
        {
          routes: '/schooling-topic-category',
          label: 'topicCategories',
          moduleColor: ModuleColor.SCHOOLING,
          module: ModuleName.SCHOOLING,
          navigation: 'schoolings',
          icon: 'category'
        },
        {
          routes: '/schooling-package',
          label: 'schoolingPackageOverview',
          moduleColor: ModuleColor.SCHOOLING,
          module: ModuleName.SCHOOLING,
          navigation: 'schoolings',
          icon: 'dataset'
        },
      ]
    },
    {
      label: 'qualifications',
      icon: 'verified',
      moduleColor: ModuleColor.QUALIFICATION,
      children: [
        // {
        //   routes: '/qualification/create',
        //   label: 'createQualification',
        //   moduleColor: ModuleColor.QUALIFICATION,
        //   module: ModuleName.QUALIFICATION,
        //   navigation: 'qualifications',
        //   icon: 'verified',
        //   permission: PermissionKeys.QUALIFICATION_EDIT,
        //   add: true
        // },
        {
          routes: '/qualification',
          label: 'qualification-overview',
          moduleColor: ModuleColor.QUALIFICATION,
          module: ModuleName.QUALIFICATION,
          navigation: 'qualifications',
          icon: 'verified'
        },
        {
          routes: '/qualification-employee/create',
          label: 'assignQualification',
          moduleColor: ModuleColor.QUALIFICATION,
          module: ModuleName.QUALIFICATION,
          navigation: 'qualifications',
          icon: 'contact_page',
          permission: PermissionKeys.QUALIFICATION_EMPLOYEE_EDIT
        },
        {
          routes: '/qualification-employee',
          label: 'qualificationsAssigned',
          moduleColor: ModuleColor.QUALIFICATION,
          module: ModuleName.QUALIFICATION,
          navigation: 'qualifications',
          icon: 'contact_page'
        },
        {
          routes: '/qualification-type',
          label: 'qualificationTypes',
          moduleColor: ModuleColor.QUALIFICATION,
          module: ModuleName.QUALIFICATION,
          navigation: 'qualifications',
          icon: 'category'
        },
        {
          routes: '/credential',
          label: 'credentials',
          moduleColor: ModuleColor.QUALIFICATION,
          module: ModuleName.QUALIFICATION,
          navigation: 'qualifications',
          icon: 'receipt_long'
        },
      ]
    },
    {
      label: 'accidentBook',
      icon: 'local_hospital',
      moduleColor: ModuleColor.MEDICAL,
      children: [
        {
          routes: '/accident-preReport',
          label: 'accident-preReport-overview',
          module: ModuleName.MEDICAL,
          moduleColor: ModuleColor.MEDICAL,
          icon: 'personal_injury',
          navigation: 'accidentBook'
        },
        // {
        //   routes: '/accident-book/create',
        //   label: 'addNewAccident',
        //   module: ModuleName.MEDICAL,
        //   moduleColor: ModuleColor.MEDICAL,
        //   icon: 'local_hospital',
        //   navigation: 'accidentBook',
        //   permission: PermissionKeys.ACCIDENT_COMPLETE_EDIT,
        //   add: true
        // },
        {
          routes: '/accident-book',
          label: 'accident-book-overview',
          module: ModuleName.MEDICAL,
          moduleColor: ModuleColor.MEDICAL,
          icon: 'local_hospital',
          navigation: 'accidentBook'
        },
      ]
    },
    {
      label: 'screening',
      icon: 'multiline_chart',
      moduleColor: ModuleColor.SCREENING,
      children: [
        // {
        //   routes: '/screening-examination/multiple',
        //   label: 'createExamination',
        //   moduleColor: ModuleColor.SCREENING,
        //   module: ModuleName.SCREENING,
        //   navigation: 'screening',
        //   icon: 'ecg',
        //   permission: PermissionKeys.SCREENING_ADD,
        //   add: true
        // },
        {
          routes: '/screening-examination',
          label: 'examination-overview',
          moduleColor: ModuleColor.SCREENING,
          module: ModuleName.SCREENING,
          navigation: 'screening',
          icon: 'ecg'
        },
        // {
        //   routes: '/screening-examination-screening',
        //   label: 'examination-screening-overview',
        //   moduleColor: ModuleColor.SCREENING,
        //   module: ModuleName.SCREENING,
        //   navigation: 'screening',
        //   icon: 'ecg'
        // },
        // {
        //   routes: '/screening/create',
        //   label: 'createScreening',
        //   moduleColor: ModuleColor.SCREENING,
        //   module: ModuleName.SCREENING,
        //   navigation: 'screening',
        //   icon: 'multiline_chart',
        //   permission: PermissionKeys.SCREENING_ADD,
        //   add: true
        // },
        {
          routes: '/screening',
          label: 'screening-overview',
          moduleColor: ModuleColor.SCREENING,
          module: ModuleName.SCREENING,
          navigation: 'screening',
          icon: 'multiline_chart'
        },
        {
          routes: '/screening-package',
          label: 'screening-package-overview',
          moduleColor: ModuleColor.SCREENING,
          module: ModuleName.SCREENING,
          navigation: 'screening',
          icon: 'dataset'
        }
      ]
    },
    {
      label: 'maintenancePlanner',
      icon: 'construction',
      moduleColor: ModuleColor.MAINTENANCEPLANNER,
      children: [
        // {
        //   routes: '/maintenance-equipment/create',
        //   label: 'createEquipment',
        //   moduleColor: ModuleColor.MAINTENANCEPLANNER,
        //   module: ModuleName.INSPECTION,
        //   navigation: 'maintenancePlanner',
        //   icon: 'construction',
        //   permission: PermissionKeys.MAINTENANCE_EDIT,
        //   add: true
        // },
        {
          routes: '/maintenance-equipment',
          label: 'equipmentOverview',
          moduleColor: ModuleColor.MAINTENANCEPLANNER,
          module: ModuleName.INSPECTION,
          navigation: 'maintenancePlanner',
          icon: 'construction'
        },
        // {
        //   routes: '/maintenance-exam/create',
        //   label: 'createExam',
        //   moduleColor: ModuleColor.MAINTENANCEPLANNER,
        //   module: ModuleName.INSPECTION,
        //   navigation: 'maintenancePlanner',
        //   icon: 'content_paste',
        //   permission: PermissionKeys.MAINTENANCE_EDIT,
        //   add: true
        // },
        {
          routes: '/maintenance-exam',
          label: 'examOverview',
          moduleColor: ModuleColor.MAINTENANCEPLANNER,
          module: ModuleName.INSPECTION,
          navigation: 'maintenancePlanner',
          icon: 'content_paste'
        },
        {
          routes: '/maintenance-exam-execution',
          label: 'examExecutionOverview',
          moduleColor: ModuleColor.MAINTENANCEPLANNER,
          module: ModuleName.INSPECTION,
          navigation: 'maintenancePlanner',
          icon: 'pending_actions'
        },
        {
          routes: '/maintenance-equipment-category',
          label: 'equipmentCategories',
          moduleColor: ModuleColor.MAINTENANCEPLANNER,
          module: ModuleName.INSPECTION,
          navigation: 'maintenancePlanner',
          icon: 'category'
        },
        {
          routes: '/maintenance-exam-type',
          label: 'examTypes',
          moduleColor: ModuleColor.MAINTENANCEPLANNER,
          module: ModuleName.INSPECTION,
          navigation: 'maintenancePlanner',
          icon: 'pie_chart'
        },
        // {
        //   routes: '/maintenance-checklist',
        //   label: 'checklists',
        //   moduleColor: ModuleColor.MAINTENANCEPLANNER,
        //   module: ModuleName.INSPECTION,
        //   navigation: 'maintenancePlanner',
        //   icon: 'assignment_turned_in'
        // },
      ]
    },
    {
      label: 'externalCompanies',
      icon: 'business_center',
      moduleColor: ModuleColor.EXTERNAL_COMPANIES,
      children: [
        // {
        //   routes: '/external-company/create',
        //   label: 'addNewExternalCompany',
        //   module: ModuleName.EXTERNAL_COMPANIES,
        //   moduleColor: ModuleColor.EXTERNAL_COMPANIES,
        //   icon: 'business_center',
        //   navigation: 'externalCompanies',
        //   permission: PermissionKeys.EXTERNAL_COMPANY_EDIT,
        //   add: true
        // },
        {
          routes: '/external-company',
          label: 'external-company-overview',
          module: ModuleName.EXTERNAL_COMPANIES,
          moduleColor: ModuleColor.EXTERNAL_COMPANIES,
          icon: 'business_center',
          navigation: 'externalCompanies'
        },
        // {
        //   routes: '/external-company-employee/create',
        //   label: 'newExternalCompanyEmployee',
        //   module: ModuleName.EXTERNAL_COMPANIES,
        //   moduleColor: ModuleColor.EXTERNAL_COMPANIES,
        //   icon: 'group',
        //   navigation: 'externalCompanies',
        //   permission: PermissionKeys.EXTERNAL_EMPLOYEE_EDIT,
        //   add: true
        // },
        {
          routes: '/external-company-employee',
          label: 'overViewExternalCompanyEmployees',
          module: ModuleName.EXTERNAL_COMPANIES,
          moduleColor: ModuleColor.EXTERNAL_COMPANIES,
          icon: 'group',
          navigation: 'externalCompanies'
        },
        {
          routes: '/external-company-category',
          label: 'externalCompanyCategories',
          module: ModuleName.EXTERNAL_COMPANIES,
          moduleColor: ModuleColor.EXTERNAL_COMPANIES,
          icon: 'category',
          navigation: 'externalCompanies'
        },
        {
          routes: '/terminals',
          label: 'terminals',
          module: ModuleName.EXTERNAL_COMPANIES,
          moduleColor: ModuleColor.EXTERNAL_COMPANIES,
          icon: 'devices',
          navigation: 'externalCompanies'
        },
      ]
    },
    {
      label: 'measures',
      icon: 'feedback',
      moduleColor: ModuleColor.MEASURE,
      children: [
        // {
        //   routes: '/measure/create',
        //   label: 'addNewMeasure',
        //   module: ModuleName.Measure,
        //   moduleColor: ModuleColor.MEASURE,
        //   icon: 'feedback',
        //   navigation: 'measures',
        //   permission: PermissionKeys.MEASURE_EDIT,
        //   add: true
        // },
        {
          routes: '/measure',
          label: 'measure-overview',
          module: ModuleName.Measure,
          moduleColor: ModuleColor.MEASURE,
          icon: 'feedback',
          navigation: 'measures'
        },
        {
          routes: '/measure-type',
          label: 'measureTypes',
          module: ModuleName.Measure,
          moduleColor: ModuleColor.MEASURE,
          icon: 'category',
          navigation: 'measures'
        },
      ]
    },
    {
      label: 'hazardousSubstanceManagement',
      icon: 'science',
      moduleColor: ModuleColor.HAZARDOUS_SUBSTANCE,
      children: [
        {
          routes: '/hazardousSubstance',
          label: 'hazardousSubstances',
          module: ModuleName.HAZARDOUS_SUBSTANCE,
          moduleColor: ModuleColor.HAZARDOUS_SUBSTANCE,
          icon: 'science',
          navigation: 'hazardousSubstanceManagement'
        },
        {
          routes: '/adr-hazardous',
          label: 'adrHazardousSubstanceTransport',
          module: ModuleName.HAZARDOUS_SUBSTANCE,
          moduleColor: ModuleColor.HAZARDOUS_SUBSTANCE,
          icon: 'legend_toggle',
          navigation: 'hazardousSubstanceManagement'
        },
      ]
    },
  ];
  public filteredCategories = [];

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.userDetails$.subscribe(() => this.filterCategories());

  }

  filterCategories() {
    this.filteredCategories = this.categories.filter((category) => !!this.filterRoutes(category.children ? category.children : []).length);
  }

  filterRoutes(routes) {
    return routes.filter((route) => !route.module || this.store.selectSnapshot(AuthState.assignedModules).includes(route.module));
  }

  filteredRoutes(routes) {
    return routes.filter((route) => !route.module || this.store.selectSnapshot(AuthState.assignedModules).includes(route.module));
  }
}
