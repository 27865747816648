import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '@app/shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TreeModule } from '@circlon/angular-tree-component';
import { RoleSelectModule } from '@shared/components/role-select/role-select.module';
import { EmployeeCategorySelectModule } from '@shared/components/employee-category-select/employee-category-select.module';
import { SearchInputModule } from '@shared/components/search-input/search-input.module';
import { DepartmentSelectModule } from '@shared/components/department-select/department-select.module';
import { CompanySelectModule } from '@shared/components/company-select/company-select.module';
import { SalutationSelectModule } from '@shared/components/salutation-select/salutation-select.module';
import { EmployeeSelectModule } from '@shared/components/employee-select/employee-select.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { ConfirmDialogModule } from '@shared/components/confirm-dialog/confirm-dialog.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CountrySelectModule } from '@shared/components/country-select/country-select.module';
import { FilterPanelModule } from '@shared/components/filter-panel/filter-panel.module';
import { EmployeeDialogModule } from '@shared/components/employee-dialog/employee-dialog.module';
import { DragAndDropPanelModule } from '@shared/components/drag-and-drop-panel/drag-and-drop-panel.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { EmployeeQuickViewModule } from '@shared/components/employee-quick-view/employee-quick-view.module';
import { FileRenameDialogModule } from '@shared/components/file-rename-dialog/file-rename-dialog.module';
import { ProfileSelectModule } from '@shared/components/profile-select/profile-select.module';
import { WysiwygEditorModule } from '@shared/components/wysiwyg-editor/wysiwyg-editor.module';
import { SearchFilterModule } from '@shared/components/search-filter/search-filter.module';
import { EmployeeCategoryFilterModule } from '@shared/components/employee-category-filter/employee-category-filter.module';
import { RoleFilterModule } from '@shared/components/role-filter/role-filter.module';
import { DepartmentFilterModule } from '@shared/components/department-filter/department-filter.module';
import { EmployeeActivityFilterModule } from '@shared/components/employee-activity-filter/employee-activity-filter.module';
import { EmployeeFilterModule } from '@shared/components/employee-filter/employee-filter.module';
import { ListViewModule } from '@shared/components/list-view/list-view.module';
import { FilterDrawerModule } from '@shared/components/filter-drawer/filter-drawer.module';
import { TemplateCategorySelectModule } from '@shared/components/template-category-select/template-category-select.module';
import { FacilityStateSelectModule } from '@shared/components/facility-state-select/facility-state-select.module';
import { HazardAssessmentTemplateDialogModule } from '@shared/components/hazard-assessment-template-dialog/hazard-assessment-template-dialog.module';
import { HazardAssessmentTemplateSelectModule } from '@shared/components/hazard-assessment-template-select/hazard-assessment-template-select.module';
import { HazardAssessmentSelectModule } from '@shared/components/hazard-assessment-select/hazard-assessment-select.module';
import { HazardAssessmentDialogModule } from '@shared/components/hazard-assessment-dialog/hazard-assessment-dialog.module';
import { HazardAssessmentTemplateCategorySelectModule } from '@shared/components/hazard-assessment-template-category-select/hazard-assessment-template-category-select.module';
import { HazardAssessmentTemplateCategoryFilterModule } from '@shared/components/hazard-assessment-template-category-filter/hazard-assessment-template-category-filter.module';
import { EmployeeActivitySelectModule } from '@shared/components/employee-activity-select/employee-activity-select.module';
import { HazardAssessmentTemplateStateSelectModule } from '@shared/components/hazard-assessment-template-state-select/hazard-assessment-template-state-select.module';
import { HazardAssessmentTemplateStateFilterModule } from '@shared/components/hazard-assessment-template-state-filter/hazard-assessment-template-state-filter.module';
import { HazardMeasureSelectModule } from '@shared/components/hazard-measure-select/hazard-measure-select.module';
import { HazardMeasureDialogModule } from '@shared/components/hazard-measure-dialog/hazard-measure-dialog.module';
import { HazardIconSelectModule } from '@shared/components/hazard-icon-select/hazard-icon-select.module';
import { HazardIconDialogModule } from '@shared/components/hazard-icon-dialog/hazard-icon-dialog.module';
import { ShowFromSubCategoryFilterModule } from '@shared/components/show-from-sub-category-filter/show-from-sub-category-filter.module';
import { FileManagementPanelModule } from '@shared/components/file-management-panel/file-management-panel.module';
import { EmployeeMultiEditDialogModule } from '@shared/components/employee-multi-edit-dialog/employee-multi-edit-dialog.module';
import { DepartmentDialogModule } from '@shared/components/department-dialog/department-dialog.module';
import { DateSelectModule } from '@shared/components/date-select/date-select.module';
import { ObservationTypeSelectModule } from '@shared/components/observation-type-select/observation-type-select.module';
import { AuditSelectModule } from '@shared/components/audit-select/audit-select.module';
import { MeasureTypeSelectModule } from '@shared/components/measure-type-select/measure-type-select.module';
import { LanguageSelectModule } from '@shared/components/language-select/language-select.module';
import { ThemeSelectModule } from '@shared/components/theme-select/theme-select.module';
import { TerminalSelectModule } from '@shared/components/terminal-select/terminal-select.module';
import { ExternalCompanySelectModule } from '@shared/components/external-company-select/external-company-select.module';
import { AccidentCauseSelectModule } from '@shared/components/accident-cause-select/accident-cause-select.module';
import { ExternalCompanyFilterModule } from '@shared/components/external-company-filter/external-company-filter.module';
import { ExternalCompanyCategorySelectModule } from '@shared/components/external-company-category-select/external-company-category-select.module';
import { ExternalCompanyCategoryFilterModule } from '@shared/components/external-company-category-filter/external-company-category-filter.module';
import { CompanyFilterModule } from '@shared/components/company-filter/company-filter.module';
import { AccidentStatusSelectModule } from '@shared/components/accident-status-select/accident-status-select.module';
import { TimeInputModule } from '@shared/components/time-input/time-input.module';
import { TerminalActivityDialogModule } from '@shared/components/terminal-activity-dialog/terminal-activity-dialog.module';
import { TerminalActivityTypeSelectModule } from '@shared/components/terminal-activity-type-select/terminal-activity-type-select.module';
import { EmptyMessageModule } from '@shared/components/empty-message/empty-message.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { ImageQuickViewModule } from '@shared/components/image-quick-view/image-quick-view.module';
import { ObservationDialogModule } from '@shared/components/observation-dialog/observation-dialog.module';
import { MeasureDialogModule } from '@shared/components/measure-dialog/measure-dialog.module';
import { AccidentStatusFilterModule } from '@shared/components/accident-status-filter/accident-status-filter.module';
import { InstructionRefreshDialogModule } from '@shared/components/instruction-refresh-dialog/instruction-refresh-dialog.module';
import { PasswordResetDialogModule } from '@shared/components/password-reset-dialog/password-reset-dialog.module';
import {ChecklistFieldDialogModule} from '@shared/components/checklist-field-dialog/checklist-field-dialog.module';
import {ChecklistFieldGroupDialogModule} from '@shared/components/checklist-field-group-dialog/checklist-field-group-dialog.module';
import {ChecklistAddExecutionDialogModule} from '@shared/components/checklist-add-execution-dialog/checklist-add-execution-dialog.module';
import {ChecklistAddCategoryDialogModule} from '@shared/components/checklist-add-category-dialog/checklist-add-category-dialog.module';
import {ChecklistDropDownDialogModule} from '@shared/components/checklist-drop-down-dialog/checklist-drop-down-dialog.module';
import {ChecklistDropDownAddEntryModule} from '@shared/components/checklist-drop-down-add-entry/checklist-drop-down-add-entry.module';
import {FacilityPartListDialogModule} from '@shared/components/facility-part-list-dialog/facility-part-list-dialog.module';
import {ModuleFilterModule} from '@shared/components/module-filter/module-filter.module';
import {EmployeeEditingFilterModule} from '@shared/components/employee-editing-filter/employee-editing-filter.module';
import {QualificationFilterModule} from '@shared/components/qualification-filter/qualification-filter.module';
import {QualificationSelectModule} from '@shared/components/qualification-select/qualification-select.module';
import {DepartmentSelectDialogModule} from '@shared/components/department-select-dialog/department-select-dialog.module';
import {BlockedMailFilterModule} from '@shared/components/blocked-mail-filter/blocked-mail-filter.module';
import {SendMailFilterModule} from '@shared/components/send-mail-filter/send-mail-filter.module';
import {DateFilterModule} from '@shared/components/date-filter/date-filter.module';
import {CostCenterSelectDialogModule} from '@shared/components/cost-center-select-dialog/cost-center-select-dialog.module';
import {ProfileSelectDialogModule} from '@shared/components/profile-select-dialog/profile-select-dialog.module';
import {EmployeeCategorySelectDialogModule} from '@shared/components/employee-category-select-dialog/employee-category-select-dialog.module';
import {ModuleSelectDialogModule} from '@shared/components/module-select-dialog/module-select-dialog.module';
import {DateSelectFilterModule} from '@shared/components/date-select-filter/date-select-filter.module';
import {LanguageSelectDialogModule} from '@shared/components/language-select-dialog/language-select-dialog.module';
import {CountrySelectDialogModule} from '@shared/components/country-select-dialog/country-select-dialog.module';
import {ObservationTypeSelectDialogModule} from '@shared/components/observation-type-select-dialog/observation-type-select-dialog.module';
import {ObservationTypeFilterModule} from '@shared/components/observation-type-filter/observation-type-filter.module';
import {AccidentBodypartInjuryModule} from '@shared/components/accident-bodypart-injury/accident-bodypart-injury.module';
import {InjurySelectDialogModule} from '@shared/components/injury-select-dialog/injury-select-dialog.module';
import {BodypartSelectDialogModule} from '@shared/components/bodypart-select-dialog/bodypart-select-dialog.module';
import {AccidentCauseSelectDialogModule} from '@shared/components/accident-cause-select-dialog/accident-cause-select-dialog.module';
import {AccidentDialogModule} from '@shared/components/accident-dialog/accident-dialog.module';
import {AccidentWitnessAddDialogModule} from '@shared/components/accident-witness-add-dialog/accident-witness-add-dialog.module';
import {
  AccidentExternalWitnessAddDialogModule
} from '@shared/components/accident-external-witness-add-dialog/accident-external-witness-add-dialog.module';
import {QualificationTypeFilterModule} from '@shared/components/qualification-type-filter/qualification-type-filter.module';
import {QualificationTypeSelectModule} from '@shared/components/qualification-type-select/qualification-type-select.module';
import {
  QualificationTypeSelectDialogModule
} from '@shared/components/qualification-type-select-dialog/qualification-type-select-dialog.module';
import {DefaultBooleanFilterModule} from '@shared/components/default-boolean-filter/default-boolean-filter.module';
import {QualificationSelectDialogModule} from '@shared/components/qualification-select-dialog/qualification-select-dialog.module';
import {QualificationStatusFilterModule} from '@shared/components/qualification-status-filter/qualification-status-filter.module';
import {CredentialSelectDialogModule} from '@shared/components/credential-select-dialog/credential-select-dialog.module';
import {CredentialSelectModule} from '@shared/components/credential-select/credential-select.module';
import {SectionQuickViewModule} from '@shared/components/section-quick-view/section-quick-view.module';
import {ChecklistCategoryDialogModule} from '@shared/components/checklist-category-dialog/checklist-category-dialog.module';
import {ExamTypeSelectDialogModule} from '@shared/components/exam-type-select-dialog/exam-type-select-dialog.module';
import {InstructionTypeFilterModule} from '@shared/components/instruction-type-filter/instruction-type-filter.module';
import {SymbolIconSelectModule} from '@shared/components/symbol-icon-select/symbol-icon-select.module';
import {
  InstructionPatternSelectDialogModule
} from '@shared/components/instruction-pattern-select-dialog/instruction-pattern-select-dialog.module';
import {InstructionPatternSelectModule} from '@shared/components/instruction-pattern-select/instruction-pattern-select.module';
import {ChecklistStatusFilterModule} from '@shared/components/checklist-status-filter/checklist-status-filter.module';
import {ImageViewerModule} from '@shared/components/image-viewer/image-viewer.module';
import {InstructionQuickViewModule} from '@shared/components/instruction-quick-view/instruction-quick-view.module';
import {InstructionQuickViewComponent} from '@shared/components/instruction-quick-view/instruction-quick-view.component';
import {LanguageSelectionModule} from '@shared/components/language-selection/language-selection.module';
import {HazardAssessmentTemplateCategorySelectDialogModule} from '@shared/components/hazard-assessment-template-category-select-dialog/hazard-assessment-template-category-select-dialog.module';
import {InstructionTypeSelectModule} from '@shared/components/instruction-type-select/instruction-type-select.module';
import {InstructionTypeSelectDialogModule} from '@shared/components/instruction-type-select-dialog/instruction-type-select-dialog.module';
import {TopicCategorySelectModule} from '@shared/components/topic-category-select/topic-category-select.module';
import {TopicCategorySelectDialogModule} from '@shared/components/topic-category-select-dialog/topic-category-select-dialog.module';
import {TopicCategoryFilterModule} from '@shared/components/topic-category-filter/topic-category-filter.module';
import {LanguageFilterModule} from '@shared/components/language-filter/language-filter.module';
import {PageTypeFilterModule} from '@shared/components/page-type-filter/page-type-filter.module';
import {BookletIndexSelectModule} from '@shared/components/booklet-index-select/booklet-index-select.module';
import {BookletIndexSelectDialogModule} from '@shared/components/booklet-index-select-dialog/booklet-index-select-dialog.module';
import {TopicSelectDialogModule} from '@shared/components/topic-select-dialog/topic-select-dialog.module';
import {TopicSelectModule} from '@shared/components/topic-select/topic-select.module';
import {TopicFilterModule} from '@shared/components/topic-filter/topic-filter.module';
import {DefaultEnumFilterModule} from '@shared/components/default-enum-filter/default-enum-filter.module';
import {HintMessageModule} from '@shared/components/hint-message/hint-message.module';
import {InfoboxDialogModule} from '@shared/components/infobox-dialog/infobox-dialog.module';
import {TopicPackageSelectModule} from '@shared/components/topic-package-select/topic-package-select.module';
import {TopicPackageSelectDialogModule} from '@shared/components/topic-package-select-dialog/topic-package-select-dialog.module';
import {ExamTypeSelectModule} from '@shared/components/exam-type-select/exam-type-select.module';
import {EquipmentCategorySelectModule} from '@shared/components/equipment-category-select/equipment-category-select.module';
import {
  EquipmentCategorySelectDialogModule
} from '@shared/components/equipment-category-select-dialog/equipment-category-select-dialog.module';
import {EquipmentCategoryFilterModule} from '@shared/components/equipment-category-filter/equipment-category-filter.module';
import {AreaLocationSelectModule} from '@shared/components/area-location-select/area-location-select.module';
import {AreaLocationSelectDialogModule} from '@shared/components/area-location-select-dialog/area-location-select-dialog.module';
import {AreaLocationFilterModule} from '@shared/components/area-location-filter/area-location-filter.module';
import {
  ExamExecutionStatusFilterModule
} from "@shared/components/exam-execution-status-filter/exam-execution-status-filter.module";
import {ExamStatusFilterModule} from "@shared/components/exam-status-filter/exam-status-filter.module";
import {ExamResultFilterModule} from "@shared/components/exam-result-filter/exam-result-filter.module";
import {EquipmentSelectModule} from "@shared/components/equipment-select/equipment-select.module";
import {EquipmentSelectDialogModule} from "@shared/components/equipment-select-dialog/equipment-select-dialog.module";
import {EquipmentFilterModule} from "@shared/components/equipment-filter/equipment-filter.module";
import {
  ExternalCompanyEmployeeSelectModule
} from "@shared/components/external-company-employee-select/external-company-employee-select.module";
import {ChecklistSelectDialogModule} from "@shared/components/checklist-select-dialog/checklist-select-dialog.module";
import {MenuSelectionDialogModule} from "@shared/components/menu-selection-dialog/menu-selection-dialog.module";
import {
  FileCategorySelectDialogModule
} from "@shared/components/file-category-select-dialog/file-category-select-dialog.module";
import {FileCategorySelectModule} from "@shared/components/file-category-select/file-category-select.module";
import {FileCategoryFilterModule} from "@shared/components/file-category-filter/file-category-filter.module";
import {
  EquipmentCategorySelectAddModule
} from "@shared/components/equipment-category-select-add/equipment-category-select-add.module";
import {AreaLocationSelectAddModule} from "@shared/components/area-location-select-add/area-location-select-add.module";
import {ScreeningSelectModule} from "@shared/components/screening-select/screening-select.module";
import {ScreeningSelectDialogModule} from "@shared/components/screening-select-dialog/screening-select-dialog.module";
import {
  ScreeningPackageSelectModule
} from "@shared/components/screening-package-select/screening-package-select.module";
import {
  ScreeningPackageSelectDialogModule
} from "@shared/components/screening-package-select-dialog/screening-package-select-dialog.module";
import {TranslationTableFilterModule} from '@shared/components/translation-table-filter/translation-table-filter.module';
import {ImageCropperDialogModule} from '@shared/components/image-cropper-dialog/image-cropper-dialog.module';
import {
  ExternalCompanySelectDialogModule
} from "@shared/components/external-company-select-dialog/external-company-select-dialog.module";
import {
  ExternalCompanyEmployeeSelectDialogModule
} from "@shared/components/external-company-employee-select-dialog/external-company-employee-select-dialog.module";
import {CreateDateModule} from '@shared/components/create-date/create-date.module';
import {
  HazardAssessmentLawCategorySelectModule
} from '@shared/components/hazard-assessment-law-category-select/hazard-assessment-law-category-select.module';
import {
  HazardAssessmentLawCategorySelectDialogModule
} from '@shared/components/hazard-assessment-law-category-select-dialog/hazard-assessment-law-category-select-dialog.module';
import {
  HazardAssessmentLawCategoryFilterModule
} from '@shared/components/hazard-assessment-law-category-filter/hazard-assessment-law-category-filter.module';
import {ExamTypeSelectAddModule} from "@shared/components/exam-type-select-add/exam-type-select-add.module";
import {
  QualificationTypeSelectAddModule
} from "@shared/components/qualification-type-select-add/qualification-type-select-add.module";
import {FileCategorySelectAddModule} from "@shared/components/file-category-select-add/file-category-select-add.module";
import {CostCenterSelectAddModule} from "@shared/components/cost-center-select-add/cost-center-select-add.module";
import {
  EmployeeCategorySelectAddModule
} from "@shared/components/employee-category-select-add/employee-category-select-add.module";
import {
  ObservationTypeSelectAddModule
} from "@shared/components/observation-type-select-add/observation-type-select-add.module";
import {
  TopicCategorySelectAddModule
} from "@shared/components/topic-category-select-add/topic-category-select-add.module";
import {HazardousClassSelectModule} from "@shared/components/hazardous-class-select/hazardous-class-select.module";
import {HazardousNumberSelectModule} from "@shared/components/hazardous-number-select/hazardous-number-select.module";
import {
  HazardousSubstanceStatementSelectDialogModule
} from "@shared/components/hazardous-substance-statement-select-dialog/hazardous-substance-statement-select-dialog.module";
import {
  PackagingRegulationSelectDialogModule
} from "@shared/components/packaging-regulation-select-dialog/packaging-regulation-select-dialog.module";
import {
  HazardousClassCodeSelectModule
} from "@shared/components/hazardous-class-code-select/hazardous-class-code-select.module";
import {PackagingGroupSelectModule} from "@shared/components/packaging-group-select/packaging-group-select.module";
import {
  TransportCategorySelectModule
} from "@shared/components/transport-category-select/transport-category-select.module";
import {
  TunnelRestrictionSelectModule
} from "@shared/components/tunnel-restriction-select/tunnel-restriction-select.module";
import {LimitedQuantitySelectModule} from "@shared/components/limited-quantity-select/limited-quantity-select.module";
import {ExemptQuantitySelectModule} from "@shared/components/exempt-quantity-select/exempt-quantity-select.module";
import {TankCodeSelectModule} from "@shared/components/tank-code-select/tank-code-select.module";
import {TankVehicleSelectModule} from "@shared/components/tank-vehicle-select/tank-vehicle-select.module";
import {StorageClassSelectModule} from "@shared/components/storage-class-select/storage-class-select.module";
import {YearSelectModule} from '@shared/components/year-select/year-select.module';
import {AreaRoomSelectModule} from "@shared/components/area-room-select/area-room-select.module";
import {
  HazardClassificationSelectModule
} from "@shared/components/hazard-classification-select/hazard-classification-select.module";
import {ProfileFilterModule} from '@shared/components/profile-filter/profile-filter.module';
import {ExplosionGroupSelectModule} from "@shared/components/explosion-group-select/explosion-group-select.module";
import {
  TemperatureClassSelectModule
} from "@shared/components/temperature-class-select/temperature-class-select.module";
import {
  WaterHazardClassSelectModule
} from "@shared/components/water-hazard-class-select/water-hazard-class-select.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
    DirectivesModule,
    TreeModule,
    SearchInputModule,
    CompanySelectModule,
    CompanyFilterModule,
    SalutationSelectModule,
    CountrySelectModule,
    FilterPanelModule,
    FilterDrawerModule,
    RoleSelectModule,
    RoleFilterModule,
    DepartmentSelectModule,
    DepartmentFilterModule,
    DepartmentDialogModule,
    EmployeeActivitySelectModule,
    EmployeeActivityFilterModule,
    EmployeeSelectModule,
    EmployeeDialogModule,
    EmployeeFilterModule,
    EmployeeCategorySelectModule,
    EmployeeCategoryFilterModule,
    EmployeeMultiEditDialogModule,
    LoadingOverlayModule,
    LoadingSpinnerModule,
    ConfirmDialogModule,
    DragAndDropPanelModule,
    EmployeeQuickViewModule,
    FileRenameDialogModule,
    ProfileSelectModule,
    WysiwygEditorModule,
    SearchFilterModule,
    ListViewModule,
    TemplateCategorySelectModule,
    FacilityStateSelectModule,
    HazardAssessmentTemplateSelectModule,
    HazardAssessmentTemplateDialogModule,
    HazardAssessmentTemplateCategorySelectModule,
    HazardAssessmentTemplateCategoryFilterModule,
    HazardAssessmentTemplateStateSelectModule,
    HazardAssessmentTemplateStateFilterModule,
    HazardAssessmentSelectModule,
    HazardAssessmentDialogModule,
    HazardMeasureSelectModule,
    HazardMeasureDialogModule,
    HazardIconSelectModule,
    HazardIconDialogModule,
    ShowFromSubCategoryFilterModule,
    FileManagementPanelModule,
    DateSelectModule,
    ObservationTypeSelectModule,
    AuditSelectModule,
	  MeasureTypeSelectModule,
    LanguageSelectModule,
    ThemeSelectModule,
    TerminalSelectModule,
    ExternalCompanySelectModule,
    AccidentCauseSelectModule,
    AccidentStatusSelectModule,
    ExternalCompanyFilterModule,
    ExternalCompanyCategorySelectModule,
    ExternalCompanyCategoryFilterModule,
    TimeInputModule,
    TerminalActivityTypeSelectModule,
    TerminalActivityDialogModule,
    EmptyMessageModule,
    ImageQuickViewModule,
    ObservationDialogModule,
    MeasureDialogModule,
    AccidentStatusFilterModule,
    InstructionRefreshDialogModule,
	  PasswordResetDialogModule,
    ChecklistFieldDialogModule,
    ChecklistFieldGroupDialogModule,
    ChecklistAddExecutionDialogModule,
    ChecklistAddCategoryDialogModule,
    ChecklistDropDownDialogModule,
    ChecklistDropDownAddEntryModule,
    FacilityPartListDialogModule,
    DefaultBooleanFilterModule,
    ModuleFilterModule,
    EmployeeEditingFilterModule,
    QualificationFilterModule,
    QualificationSelectModule,
    DepartmentSelectDialogModule,
    BlockedMailFilterModule,
    SendMailFilterModule,
    DateFilterModule,
    CostCenterSelectDialogModule,
    ProfileSelectDialogModule,
    EmployeeCategorySelectDialogModule,
    ModuleSelectDialogModule,
    DateSelectFilterModule,
    LanguageSelectDialogModule,
    CountrySelectDialogModule,
    ObservationTypeSelectDialogModule,
    ObservationTypeFilterModule,
    AccidentBodypartInjuryModule,
    InjurySelectDialogModule,
    BodypartSelectDialogModule,
    AccidentCauseSelectDialogModule,
    AccidentBodypartInjuryModule,
    AccidentDialogModule,
    AccidentWitnessAddDialogModule,
    AccidentExternalWitnessAddDialogModule,
    QualificationTypeSelectModule,
    QualificationTypeFilterModule,
    QualificationTypeSelectDialogModule,
    QualificationSelectDialogModule,
    QualificationStatusFilterModule,
    CredentialSelectDialogModule,
    CredentialSelectModule,
    AccidentExternalWitnessAddDialogModule,
    SectionQuickViewModule,
    ChecklistCategoryDialogModule,
    ExamTypeSelectDialogModule,
    InstructionTypeFilterModule,
    SymbolIconSelectModule,
    InstructionPatternSelectDialogModule,
    InstructionPatternSelectModule,
    ImageViewerModule,
    InstructionQuickViewModule,
    InstructionPatternSelectModule,
    ChecklistStatusFilterModule,
    LanguageSelectionModule,
    HazardAssessmentTemplateCategorySelectDialogModule,
    InstructionTypeSelectModule,
    InstructionTypeSelectDialogModule,
    LanguageFilterModule,
    TopicCategoryFilterModule,
    TopicCategorySelectModule,
    TopicCategorySelectDialogModule,
    PageTypeFilterModule,
    BookletIndexSelectModule,
    BookletIndexSelectDialogModule,
    TopicSelectDialogModule,
    TopicSelectModule,
    TopicFilterModule,
    DefaultEnumFilterModule,
    HintMessageModule,
    InfoboxDialogModule,
    TopicPackageSelectModule,
    TopicPackageSelectDialogModule,
    ExamTypeSelectModule,
    EquipmentCategorySelectModule,
    EquipmentCategorySelectDialogModule,
    EquipmentCategoryFilterModule,
    AreaLocationSelectModule,
    AreaLocationSelectDialogModule,
    AreaLocationFilterModule,
    ExamExecutionStatusFilterModule,
    ExamStatusFilterModule,
    ExamResultFilterModule,
    EquipmentSelectModule,
    EquipmentSelectDialogModule,
    EquipmentFilterModule,
    ExternalCompanyEmployeeSelectModule,
    ChecklistSelectDialogModule,
    MenuSelectionDialogModule,
    FileCategorySelectDialogModule,
    FileCategorySelectModule,
    FileCategoryFilterModule,
    EquipmentCategorySelectAddModule,
    AreaLocationSelectAddModule,
    ScreeningSelectModule,
    ScreeningSelectDialogModule,
    ScreeningPackageSelectModule,
    ScreeningPackageSelectDialogModule,
    TranslationTableFilterModule,
    ImageCropperDialogModule,
    ExternalCompanySelectDialogModule,
    ExternalCompanyEmployeeSelectDialogModule,
    CreateDateModule,
    HazardAssessmentLawCategorySelectModule,
    HazardAssessmentLawCategorySelectDialogModule,
    HazardAssessmentLawCategoryFilterModule,
    ExamTypeSelectAddModule,
    QualificationTypeSelectAddModule,
    FileCategorySelectAddModule,
    CostCenterSelectAddModule,
    EmployeeCategorySelectAddModule,
    ObservationTypeSelectAddModule,
    TopicCategorySelectAddModule,
    HazardousClassSelectModule,
    HazardousNumberSelectModule,
    HazardousSubstanceStatementSelectDialogModule,
    SymbolIconSelectModule,
    PackagingRegulationSelectDialogModule,
    HazardousClassCodeSelectModule,
    PackagingGroupSelectModule,
    TransportCategorySelectModule,
    TunnelRestrictionSelectModule,
    LimitedQuantitySelectModule,
    ExemptQuantitySelectModule,
    TankCodeSelectModule,
    TankVehicleSelectModule,
    StorageClassSelectModule,
    TopicCategorySelectAddModule,
    YearSelectModule,
    AreaRoomSelectModule,
    HazardClassificationSelectModule,
    YearSelectModule,
    ProfileFilterModule,
    ExplosionGroupSelectModule,
    TemperatureClassSelectModule,
    WaterHazardClassSelectModule
  ],
  exports: [
    DateFilterModule,
    SendMailFilterModule,
    BlockedMailFilterModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
    DirectivesModule,
    TreeModule,
    SearchInputModule,
    CompanySelectModule,
    CompanyFilterModule,
    SalutationSelectModule,
    CountrySelectModule,
    FilterPanelModule,
    FilterDrawerModule,
    RoleSelectModule,
    RoleFilterModule,
    DepartmentSelectModule,
    DepartmentFilterModule,
    DepartmentDialogModule,
    EmployeeActivitySelectModule,
    EmployeeActivityFilterModule,
    EmployeeSelectModule,
    EmployeeDialogModule,
    EmployeeFilterModule,
    EmployeeCategorySelectModule,
    EmployeeCategoryFilterModule,
    EmployeeMultiEditDialogModule,
    LoadingOverlayModule,
    LoadingSpinnerModule,
    ConfirmDialogModule,
    DragAndDropPanelModule,
    EmployeeQuickViewModule,
    FileRenameDialogModule,
    ProfileSelectModule,
    WysiwygEditorModule,
    SearchFilterModule,
    ListViewModule,
    TemplateCategorySelectModule,
    FacilityStateSelectModule,
    HazardAssessmentTemplateSelectModule,
    HazardAssessmentTemplateDialogModule,
    HazardAssessmentTemplateCategorySelectModule,
    HazardAssessmentTemplateCategoryFilterModule,
    HazardAssessmentTemplateStateSelectModule,
    HazardAssessmentTemplateStateFilterModule,
    HazardAssessmentSelectModule,
    HazardAssessmentDialogModule,
    HazardMeasureSelectModule,
    HazardMeasureDialogModule,
    HazardIconSelectModule,
    HazardIconDialogModule,
    ShowFromSubCategoryFilterModule,
    FileManagementPanelModule,
    DateSelectModule,
    ObservationTypeSelectModule,
    AuditSelectModule,
  	MeasureTypeSelectModule,
    LanguageSelectModule,
    ThemeSelectModule,
    TerminalSelectModule,
    ExternalCompanySelectModule,
    AccidentCauseSelectModule,
    AccidentStatusSelectModule,
    ExternalCompanyFilterModule,
    ExternalCompanyCategorySelectModule,
    ExternalCompanyCategoryFilterModule,
    TimeInputModule,
    TerminalActivityTypeSelectModule,
    TerminalActivityDialogModule,
    EmptyMessageModule,
    ImageQuickViewModule,
    ObservationDialogModule,
    MeasureDialogModule,
    AccidentStatusFilterModule,
    InstructionRefreshDialogModule,
	  PasswordResetDialogModule,
    ChecklistFieldDialogModule,
    ChecklistFieldGroupDialogModule,
    ChecklistAddExecutionDialogModule,
    ChecklistAddCategoryDialogModule,
    ChecklistDropDownDialogModule,
    ChecklistDropDownAddEntryModule,
    FacilityPartListDialogModule,
    DefaultBooleanFilterModule,
    ModuleFilterModule,
    EmployeeEditingFilterModule,
    QualificationFilterModule,
    QualificationSelectModule,
    DepartmentDialogModule,
    CostCenterSelectDialogModule,
    ProfileSelectDialogModule,
    EmployeeCategorySelectDialogModule,
    ModuleSelectDialogModule,
    DateSelectFilterModule,
    LanguageSelectDialogModule,
    CountrySelectDialogModule,
    ObservationTypeSelectDialogModule,
    ObservationTypeFilterModule,
    AccidentBodypartInjuryModule,
    InjurySelectDialogModule,
    BodypartSelectDialogModule,
    AccidentCauseSelectDialogModule,
    AccidentBodypartInjuryModule,
    AccidentDialogModule,
    AccidentWitnessAddDialogModule,
    AccidentExternalWitnessAddDialogModule,
    QualificationTypeSelectModule,
    QualificationTypeFilterModule,
    QualificationTypeSelectDialogModule,
    QualificationSelectDialogModule,
    QualificationStatusFilterModule,
    CredentialSelectDialogModule,
    CredentialSelectModule,
    AccidentExternalWitnessAddDialogModule,
    SectionQuickViewModule,
    ChecklistCategoryDialogModule,
    ExamTypeSelectDialogModule,
    InstructionTypeFilterModule,
    SymbolIconSelectModule,
    InstructionPatternSelectDialogModule,
    InstructionPatternSelectModule,
    ImageViewerModule,
    InstructionQuickViewComponent,
    InstructionPatternSelectModule,
    ChecklistStatusFilterModule,
    LanguageSelectionModule,
    HazardAssessmentTemplateCategorySelectDialogModule,
    InstructionTypeSelectModule,
    InstructionTypeSelectDialogModule,
    LanguageFilterModule,
    TopicCategoryFilterModule,
    TopicCategorySelectModule,
    TopicCategorySelectDialogModule,
    PageTypeFilterModule,
    BookletIndexSelectModule,
    BookletIndexSelectDialogModule,
    TopicSelectDialogModule,
    TopicSelectModule,
    TopicFilterModule,
    DefaultEnumFilterModule,
    HintMessageModule,
    InfoboxDialogModule,
    TopicPackageSelectModule,
    TopicPackageSelectDialogModule,
    ExamTypeSelectModule,
    EquipmentCategorySelectModule,
    EquipmentCategorySelectDialogModule,
    EquipmentCategoryFilterModule,
    AreaLocationSelectModule,
    AreaLocationSelectDialogModule,
    AreaLocationFilterModule,
    ExamExecutionStatusFilterModule,
    ExamStatusFilterModule,
    ExamResultFilterModule,
    EquipmentSelectModule,
    EquipmentSelectDialogModule,
    EquipmentFilterModule,
    ExternalCompanyEmployeeSelectModule,
    ChecklistSelectDialogModule,
    MenuSelectionDialogModule,
    FileCategorySelectDialogModule,
    FileCategorySelectModule,
    FileCategoryFilterModule,
    EquipmentCategorySelectAddModule,
    AreaLocationSelectAddModule,
    ScreeningSelectModule,
    ScreeningSelectDialogModule,
    ScreeningPackageSelectModule,
    ScreeningPackageSelectDialogModule,
    TranslationTableFilterModule,
    ImageCropperDialogModule,
    ExternalCompanySelectDialogModule,
    ExternalCompanyEmployeeSelectDialogModule,
    CreateDateModule,
    HazardAssessmentLawCategorySelectModule,
    HazardAssessmentLawCategorySelectDialogModule,
    HazardAssessmentLawCategoryFilterModule,
    ExamTypeSelectAddModule,
    QualificationTypeSelectAddModule,
    FileCategorySelectAddModule,
    CostCenterSelectAddModule,
    EmployeeCategorySelectAddModule,
    ObservationTypeSelectAddModule,
    TopicCategorySelectAddModule,
    HazardousClassSelectModule,
    HazardousNumberSelectModule,
    HazardousSubstanceStatementSelectDialogModule,
    SymbolIconSelectModule,
    PackagingRegulationSelectDialogModule,
    PackagingRegulationSelectDialogModule,
    HazardousClassCodeSelectModule,
    PackagingGroupSelectModule,
    TransportCategorySelectModule,
    TunnelRestrictionSelectModule,
    LimitedQuantitySelectModule,
    ExemptQuantitySelectModule,
    TankCodeSelectModule,
    TankVehicleSelectModule,
    StorageClassSelectModule,
    TopicCategorySelectAddModule,
    YearSelectModule,
    AreaRoomSelectModule,
    HazardClassificationSelectModule,
    YearSelectModule,
    ProfileFilterModule,
    ExplosionGroupSelectModule,
    TemperatureClassSelectModule,
    WaterHazardClassSelectModule
  ],
})
export class SharedModule {}
